<template>
  <div class="card-wrapper">
    <router-link :to="{ name: 'Home' }">
      <img src="../../assets/images/logo.png" alt="Reduct logo" />
    </router-link>

    <div class="card">
      <div class="card-header">
        <div class="card-header-title">{{ $t("password_reset") }}</div>
      </div>

      <div class="card-content">
        <form @submit.prevent="submit">
          <CustomInput
            v-model="password"
            :label="$t('password_new')"
            :horizontal="false"
            :type="'password'"
            :icon="'lock'"
          >
          </CustomInput>

          <b-button
            expanded
            type="is-primary"
            native-type="submit"
            class="mt-5"
            :loading="loading"
          >
            {{ $t("submit") }}
          </b-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CustomInput from "../../components/forms/CustomInput";

export default {
  name: "ResetPassword",
  components: { CustomInput },
  data() {
    return {
      token: this.$route.query.token,
      loading: false,
      password: null,
    };
  },
  mounted() {
    if (!this.token) {
      this.$router.push({ name: "Account" });
    }
  },
  methods: {
    ...mapActions(["resetPasswordSubmit"]),
    submit() {
      this.loading = true;

      this.resetPasswordSubmit({ token: this.token, password: this.password })
        .then(() => {
          this.$buefy.toast.open({
            message: this.$t("password_reset_submitted"),
            type: "is-primary",
          });
          this.$router.push({ name: "Login" });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
